import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextField from "@material-ui/core/TextField";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import plugins from "suneditor/src/plugins";

import { ApolloError, useMutation } from "@apollo/client";
import S3FileUpload from "react-s3";
import { SEND_NEWSLETTER, UPDATE_ARTICLE } from "../Apollo/Mutation";
import * as D from "../Componet/myDate";
import "../css/ArticleEditForm.css";
import ClearContext from "../libraries/ClearContext";
import axios from "../libraries/axios";
import AuthContext from "../libraries/context.js";
import Loader from "./Loader";

function ArticleEditForm({
  cName,
  cId,
  setSelectedArticleId,
  selectedArticleId,
}) {
  const Image = <FontAwesomeIcon className="img" id="img" icon={faImage} />;
  <i class="fas fa-less-than"></i>;
  const [updateArtical] = useMutation(UPDATE_ARTICLE);
  const [artical, setArtical] = useState([]);
  const context = useContext(AuthContext);
  const token = context.user.token;
  const [visibility, setVisibility] = useState(false);
  const [addition, setAddition] = useState("");
  const [editor, setEditor] = useState("");
  const [date, setDate] = useState("");
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [para1, setPara1] = useState(null);
  const [para2, setPara2] = useState(null);
  const [para3, setPara3] = useState(null);

  const [editUploadImg1, setEditUploadImg1] = useState("");
  const [editUploadImg2, setEditUploadImg2] = useState("");
  const clearContext = useContext(ClearContext);
  const [currentID, setCurrentId] = useState(null);
  const ed = useRef();

  const [sendNewsletter] = useMutation(SEND_NEWSLETTER);

  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor) => {
    ed.current = "dfsdfsdfsdf";
  };
  const sunEdidtorOption = {
    plugins: plugins,
    width: "100%",
    // height: "200px",
    minHeight: "200px",
    buttonList: [
      // Default
      ["undo", "redo"],
      ["font", "fontSize", "formatBlock"],
      ["paragraphStyle", "blockquote"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor", "textStyle"],
      ["removeFormat"],
      ["outdent", "indent"],
      ["align", "horizontalRule", "list", "lineHeight"],
      ["table", "link", "image", "video", "audio"],
      ["imageGallery"],
      ["fullScreen", "showBlocks", "codeView"],
      ["preview", "print"],
      ["save", "template"],
    ],
    historyStackDelayTime: 100,
    attributesWhitelist: {
      all: "style",
    },
  };

  useEffect(() => {
    FetchArtical();
  }, []);

  useEffect(() => {
    FetchArtical();
    setSubTitle("");
    setEditor("");
    setTitle("");
    setEditUploadImg1("");
    setEditUploadImg2("");
    setAddition("");
    setDate("");
    setPara1(null);
    setPara2(null);
    setPara3(null);

    setCurrentId(null);
  }, [clearContext]);

  const Save = (e) => {
    e.preventDefault();
    console.log("images", editUploadImg1, currentID);
    if (title.toString().trim() === "") {
      alert("Title canot be empty!");
    } else if (subTitle.toString().trim() === "") {
      alert("Sub Title canot be empty!");
    } else if (
      para1.toString().trim() == "" &&
      para2.toString().trim() == "" &&
      para3.toString().trim() == ""
    ) {
      alert("An article should have Minimum 3 paragah!");
    } else {
      updateArtical({
        variables: {
          id: currentID,
          editor: editor,
          title: title,
          subTitle: subTitle,
          addition: addition,
          article1: para1,
          article2: para2,
          article3: para3,
        },
      }).then((res) => {
        const data = res.data.updateArtical;
        if (data != null) {
          FetchArtical();
          window.scroll(0, 0);
          setSubTitle("");
          setEditor("");
          setTitle("");
          setEditUploadImg1("");
          setEditUploadImg2("");
          setAddition("");
          setDate("");
          setPara1(null);
          setPara2(null);
          setPara3(null);
          setCurrentId(null);
          alert("Updated Successfully");
        } else {
          alert(res.data.errors[0].message);
        }
      });

      // const mainArticle = JSON.stringify(
      //   convertToRaw(para1?.getCurrentContent())
      // );
      // const main2Article = JSON.stringify(
      //   convertToRaw(para2?.getCurrentContent())
      // );
      // const articles = JSON.stringify(convertToRaw(para3?.getCurrentContent()));
      // try {
      //   axios({
      //     method: "POST",
      //     data: {
      //       query: `mutation {
      //       updateArtical(
      //         where: {
      //               id: "${currentID}"
      //            }
      //         data: {
      //           editer:"${editor}"
      //           title: "${title}"
      //           subTitle: "${subTitle}"
      //           titleImage: ""
      //           volume: "${addition}"
      //           pharagraph01: """${para1}"""
      //           pharagraph02: """${para2}"""
      //           pharagraph03: """${para3}"""
      //         }
      //       ) {
      //         id
      //       }
      //     }
      //     `,
      //     },
      //     headers: {
      //       Accept: `application/json`,
      //       Authorization: token,
      //       "Content-Type": "application/json",
      //     },
      //   })
      //     .then((res) => {
      //       const data = res.data.data.updateArtical;
      //       if (data != null) {
      //         FetchArtical();
      //         window.scroll(0, 0);
      //         setSubTitle("");
      //         setEditor("");
      //         setTitle("");
      //         setEditUploadImg1("");
      //         setEditUploadImg2("");
      //         setAddition("");
      //         setDate("");
      //         setPara1(null);
      //         setPara2(null);
      //         setPara3(null);
      //         setCurrentId(null);
      //         alert("Updated Successfully");
      //       } else {
      //         alert(res.data.errors[0].message);
      //       }
      //     })
      //     .catch((error) => {
      //       console.error("FetcingError while adding category ", error);
      //     });
      // } catch (err) {
      //   console.log("Error", err);
      // }
    }
  };

  const Delete = (e) => {
    e.preventDefault();
    window.scroll(0, 0);
    if (currentID !== null) {
      var r = window.confirm("Do you want Detele this Article");
      if (r === true) {
        try {
          axios({
            method: "POST",
            data: {
              query: `mutation {
            deleteArtical (where:{id:"${currentID}"}){
              id
            }
          }
        `,
            },
            headers: {
              Accept: `application/json`,
              Authorization: token,
              "Content-Type": "application/json",
            },
          })
            .then((res) => {
              console.info("added data ", res.data);
              const data = res.data.data.deleteArtical;
              if (data != null) {
                FetchArtical();
                alert("Delete Successfully");
              } else {
                alert(res.data.errors[0].message);
                setSubTitle("");
                setEditor("");
                setTitle("");
                setEditUploadImg1("");
                setEditUploadImg2("");
                setAddition("");
                setDate("");
                setPara1(null);
                setPara2(null);
                setPara3(null);
                setCurrentId(null);
              }
            })
            .catch((error) => {
              console.error("FetcingError while adding category ", error);
            });
        } catch (err) {
          console.log("Error", err);
        }
      }
    } else {
      alert("Please select article");
    }
  };

  // const EditUploadImgf1 = (e) => {
  //   e.preventDefault();
  //   const econfig1 = {
  //     bucketName: "respect",
  //     dirName: `categories/${cName}/titleImage`,
  //     ACL: "public-read",
  //     region: "ap-south-1",
  //     accessKeyId: "AKIAR5OK4LXS37SFPQEL",
  //     secretAccessKey: "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",
  //   };
  //   try {
  //     S3FileUpload.uploadFile(e.target.files[0], econfig1)
  //       .then((data) => {
  //         console.log("image data", data);
  //         const img1 = data.location;
  //         setEditUploadImg1(img1);
  //         // Save(img1);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } catch (error) {
  //     console.log("error while uploading image", error);
  //   }
  // };
  const EditUploadImgf2 = (e) => {
    e.preventDefault();
    const econfig2 = {
      bucketName: "respect",
      dirName: `categories/${cName}/subTitleImage`,
      ACL: "public-read",
      region: "ap-south-1",
      accessKeyId: "AKIAR5OK4LXS37SFPQEL",
      secretAccessKey: "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",
    };
    try {
      S3FileUpload.uploadFile(e.target.files[0], econfig2)
        .then((data) => {
          console.log("image data", data);
          const img2 = data.location;
          setEditUploadImg2(img2);
          // Save(img2);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log("error while uploading image", error);
    }
  };

  const EditArt = (id) => {
    setSelectedArticleId(id);
    setPara1(null);
    setPara2(null);
    setPara3(null);
    setTimeout(() => {
      artical?.map((item) => {
        if (item.id === id) {
          setCurrentId(id);
          setAddition(item?.volume);
          setEditor(item?.editer);
          setDate(new Date(parseInt(item?.updatedAt)));
          setTitle(item?.title);
          setSubTitle(item?.subTitle);

          setPara1(item?.pharagraph01);
          setPara2(item?.pharagraph02);
          setPara3(item?.pharagraph03);
          setEditUploadImg1(item?.titleImage);
        }
      });
    }, 500);
  };

  const FetchArtical = async () => {
    try {
      setVisibility(true);
      await axios({
        method: "POST",

        data: {
          query: `query {
            articals(where: { categoryName: "${cName}" }) {
              id
              categoryId
              title
              subTitle
              editer
              pharagraph01
              pharagraph02
              pharagraph03
              titleImage
              categoryName
              updatedAt
              volume
            }
          }

          `,
        },
        headers: {
          Accept: `application/json`,
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          const data = res.data.data.articals;
          console.log(cId, "ARTICAL", data);

          if (data != null) {
            const updateArticals = data?.map((item) => {
              const bytes1 = item?.pharagraph01;
              const bytes2 = item?.pharagraph02;
              const bytes3 = item?.pharagraph03;

              // const bytes3 = await base64.decode(item?.pharagraph03);

              // const decryptedData1 = JSON.parse(bytes1);
              // const decryptedData2 = JSON.parse(bytes2);
              // const decryptedData3 = JSON.parse(bytes3);

              // const decryptedData3 = await JSON.parse(bytes3);

              // const para1 = decryptedData1;
              // const para2 = decryptedData2;
              // const para3 = decryptedData3;
              // const para3 = decryptedData3;
              // console.log(
              //   "<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>",
              //   base64.decode(item?.pharagraph02)
              // );

              return {
                id: item?.id,
                categoryId: item?.categoryId,
                title: item?.title,
                subTitle: item?.subTitle,
                editer: item?.editer,
                pharagraph01: bytes1,
                pharagraph02: bytes2,
                pharagraph03: bytes3,
                titleImage: item?.titleImage,
                categoryName: item?.categoryName,
                updatedAt: item?.updatedAt,
                volume: item?.volume,
              };
            });
            console.log(data);
            setArtical(updateArticals);
          } else {
            alert(res.data.errors[0].message);
          }
          setVisibility(false);
        })
        .catch((error) => {
          console.error("Article FetcingError ", error);
          setVisibility(false);
        });
    } catch (err) {
      console.log("ArticalesError", err);
    }
  };

  async function handleClickSendNewsletter() {
    try {
      if (selectedArticleId === null) return undefined;

      const response = await sendNewsletter({
        variables: { articleId: selectedArticleId },
      });

      if (response.data.success === false) {
        alert("Error occurred while try to send newsletters");
        return undefined;
      }

      alert("Newsletters successfully sent.");
    } catch (error) {
      if (error instanceof ApolloError)
        alert("Error occurred while try to send newsletters");
    }
  }

  return (
    <div className="edit_article">
      <div className="edit_article_container">
        <Form className="edit_container_form">
          <Form.Group
            as={Col}
            controlId="formPlaintextEmail"
            className="edit_form_row"
          >
            <Form.Label column sm="2" className="edit_form_label">
              Addition
            </Form.Label>
            <Col sm="10">
              <div className="tbl">
                <Loader visibility={visibility} />
                <Table className="edit_form_table">
                  <thead>
                    <tr className="tbl_header">
                      <td>Addition</td>
                      <td>Title</td>
                      <td>Editor</td>
                      <td>Date and Time</td>
                    </tr>
                  </thead>
                  <tbody>
                    {artical?.map((item) => {
                      if (item.categoryId === cId) {
                        return (
                          <tr
                            className="selectTheRow"
                            onClick={() => EditArt(item.id)}
                          >
                            <td>{item.volume}</td>
                            <td>{item.title}</td>
                            <td>{item.editer}</td>
                            <td>{D.DateAndTime(item.updatedAt)}</td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            controlId="formPlaintextEmail"
            className="edit_form_row"
          >
            <Form.Label column sm="2" className="edit_form_label">
              Addition
            </Form.Label>
            <Col sm="10">
              <Form.Control
                className="edit_form_control"
                id="edit_form_control"
                type="number"
                value={addition}
                onChange={(e) => setAddition(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            controlId="formPlaintextEmail"
            className="edit_form_row"
          >
            <Form.Label column sm="2" className="edit_form_label">
              Editor
            </Form.Label>
            <Col sm="10">
              <Form.Control
                className="edit_form_control"
                id="edit_form_control2"
                type="text"
                value={editor}
                onChange={(e) => setEditor(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            controlId="formPlaintextEmail"
            className="edit_form_row"
          >
            <Form.Label column sm="2" className="edit_form_label">
              Date
            </Form.Label>
            <Col sm="10" style={{ display: "flex" }}>
              <DatePicker
                className="datePickerStyle form_control"
                selected={date}
                onChange={(e) => setDate(e)}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            controlId="formPlaintextEmail"
            className="edit_form_row"
          >
            <Form.Label column sm="2" className="edit_form_label">
              Title
            </Form.Label>
            <Col sm="10">
              <Form.Control
                as="textarea"
                className="edit_form_control_tittle"
                id="edit_form_control_tittle"
                rows={3}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            controlId="formPlaintextEmail"
            className="edit_form_row"
          >
            <Form.Label column sm="2" className="edit_form_label">
              Sub Title
            </Form.Label>
            <Col sm="10">
              <Form.Control
                as="textarea"
                className="edit_form_control_tittle"
                id="edit_form_control_tittle2"
                rows={3}
                value={subTitle}
                onChange={(e) => setSubTitle(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            controlId="formPlaintextEmail"
            className="edit_form_row"
          >
            <Form.Label column sm="2" className="edit_form_label">
              Image
            </Form.Label>
            <Col sm="10" className="edit_form_img_row">
              {/* <label
                className="edit_form_control_image"
                type="file"
                style={{ fontSize: "32px" }}
              >
                <input
                  id="file-input"
                  type="file"
                  onChange={(e) => {
                    EditUploadImgf1(e);
                    e.preventDefault();
                    let i1 = e.target.files[0];
                    console.log("check", i1);
                    setEditUploadImg1(i1);
                  }}
                />
                {editUploadImg1 === null ? (
                  <i className="img">{Image}</i>
                ) : (
                  <img
                    className="edit_form_control_image__uploaded"
                    id="editUploadImage1"
                    src={editUploadImg1}
                    alt=""
                  />
                )}
              </label> */}
              <div>
                <p>Image URL Generator</p>
                <label className="edit_form_control_imaget" type="file">
                  <input
                    id="file-input"
                    type="file"
                    onChange={(e) => {
                      EditUploadImgf2(e);
                      e.preventDefault();
                      let i2 = e.target.files[0];

                      setEditUploadImg2(i2);
                    }}
                  />
                  {editUploadImg2 === null ? (
                    <i className="img">{Image}</i>
                  ) : (
                    <img
                      className="edit_form_control_image__uploadedt"
                      id="editUploadImage2"
                      src={editUploadImg2}
                      alt=""
                    />
                  )}
                  I
                </label>
                <TextField
                  className="findUrl"
                  variant="outlined"
                  label="URL"
                  fullWidth
                  style={{ marginTop: 10, marginBottom: 15 }}
                  value={editUploadImg2}
                />
              </div>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            controlId="formPlaintextEmail"
            className="edit_form_row"
          >
            <Form.Label column sm="2" className="edit_form_label">
              Article
            </Form.Label>
            <Col sm="10">
              <div className="edit_article_slots">
                <Col sm="10">
                  <div className="form_control_atl">
                    <label>Main paragraph</label>
                    {/* <Editor
                      editorState={para1}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={(e) => setPara1(e)}
                    /> */}
                    {para1 === null ? (
                      <h2>Please select Article</h2>
                    ) : (
                      <SunEditor
                        placeholder="Please type here..."
                        setDefaultStyle="font-family: arial; font-size: 12pt;line-height: 1.5;text-align:left;"
                        name="Title para"
                        setOptions={sunEdidtorOption}
                        onChange={(e) => setPara1(e)}
                        getSunEditorInstance={getSunEditorInstance}
                        defaultValue={para1}
                      />
                    )}
                    <div>
                      <button
                        variant="contained"
                        onClick={(e) => {
                          e.preventDefault();
                          setPara1(null);
                        }}
                      >
                        Clear main Paragraph
                      </button>
                    </div>
                  </div>
                </Col>

                <Col sm="10">
                  <div className="form_control_atl">
                    <label>2nd Main paragraph</label>
                    {/* <Editor
                      editorState={para2}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={(e) => setPara2(e)}
                    /> */}
                    {para2 === null ? (
                      <h2>Please select Article</h2>
                    ) : (
                      <SunEditor
                        placeholder="Please type here..."
                        setDefaultStyle="font-family: arial; font-size: 12pt;line-height: 1.5;text-align:left;"
                        name="Title para"
                        setOptions={sunEdidtorOption}
                        onChange={(e) => setPara2(e)}
                        defaultValue={para2}
                      />
                    )}
                    <div>
                      <button
                        variant="contained"
                        onClick={(e) => {
                          e.preventDefault();
                          setPara2(null);
                        }}
                      >
                        Clear 2nd Main Paragraph
                      </button>
                    </div>
                  </div>
                </Col>

                <Col sm="10">
                  <div className="form_control_atl">
                    <label>Artical paragraph</label>
                    {/* <Editor
                      editorState={para3}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={(e) => setPara3(e)}
                    /> */}
                    {para3 === null ? (
                      <h2>Please select Article</h2>
                    ) : (
                      <SunEditor
                        placeholder="Please type here..."
                        setDefaultStyle="font-family: arial; font-size: 12pt;line-height: 1.5;text-align:left;"
                        name="Title para"
                        setOptions={sunEdidtorOption}
                        onChange={(e) => setPara3(e)}
                        defaultValue={para3}
                      />
                    )}
                    <div>
                      <button
                        variant="contained"
                        onClick={(e) => {
                          e.preventDefault();
                          setPara3(null);
                        }}
                      >
                        Clear Artical Paragraph
                      </button>
                    </div>
                  </div>
                </Col>
              </div>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            controlId="formPlaintextEmail"
            className="edit_form_row"
          >
            <Form.Label column sm="2" className="edit_form_label"></Form.Label>
            <Col sm="10" className="btns">
              <Button
                className="btn_edit"
                style={{ marginRight: "4em" }}
                onClick={handleClickSendNewsletter}
              >
                Send Newsletter
              </Button>
              <Button className="btn_edit" onClick={(e) => Save(e)}>
                Update
              </Button>
              <Button className="btn_delete" onClick={(e) => Delete(e)}>
                Delete
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
}

export default ArticleEditForm;
