import { useMutation } from "@apollo/client";

import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import React, { useContext, useState } from "react";

import DatePicker from "react-datepicker";

import S3FileUpload from "react-s3";

import { Col, Form, Row } from "react-bootstrap";

import SunEditor from "suneditor-react";
import plugins from "suneditor/src/plugins";

import { CREATE_ARTICLE, SEND_NEWSLETTER } from "../Apollo/Mutation";

import AuthContext from "../libraries/context.js";

import { ApolloError } from "@apollo/client";
import "react-datepicker/dist/react-datepicker.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "suneditor/dist/css/suneditor.min.css";
import "../css/ArticleForm.css";

function ArticleForm({
  Cid,
  Ctl,
  addition,
  date,
  editor,
  title,
  subTitle,
  setEditor,
  setAddition,
  setDate,
  setTitle,
  setSubTitle,
  setArticle4,
  setArticle5,
  setArticle6,
  setImge1,
  setImge2,
  setImge3,
  setImge4,
  setImge5,
  setImge6,
}) {
  const Image = <FontAwesomeIcon className="img" id="img" icon={faImage} />;
  <i class="fas fa-less-than"></i>;
  const [createArtical] = useMutation(CREATE_ARTICLE);

  const [uploadImg1, setUploadImg1] = useState(null);
  const [uploadImg2, setUploadImg2] = useState();
  const [article1, setArticle1] = useState();
  const [article2, setArticle2] = useState();
  const [article3, setArticle3] = useState();

  const context = useContext(AuthContext);

  const [lastArticleId, setLastArticleId] = useState(
    /**@type {null|string}*/ (null)
  );
  const [sendNewsletter] = useMutation(SEND_NEWSLETTER);

  const sunEdidtorOption = {
    plugins: plugins,
    width: "100%",
    // height: "200px",
    minHeight: "200px",
    buttonList: [
      // Default
      ["undo", "redo"],
      ["font", "fontSize", "formatBlock"],
      ["paragraphStyle", "blockquote"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor", "textStyle"],
      ["removeFormat"],
      ["outdent", "indent"],
      ["align", "horizontalRule", "list", "lineHeight"],
      ["table", "link", "image", "video", "audio"],
      ["imageGallery"],
      ["fullScreen", "showBlocks", "codeView"],
      ["preview", "print"],
      ["save", "template"],
    ],
    historyStackDelayTime: 100,
    attributesWhitelist: {
      all: "style",
    },
  };

  const Submit = async (e) => {
    e?.preventDefault();

    if (title.toString().trim() === "") {
      alert("Title cannot be empty!");
    } else if (subTitle.toString().trim() === "") {
      alert("Sub Title cannot be empty!");
    } else if (
      editor.toString().trim() === "" &&
      article1.toString().trim() === "" &&
      article2.toString().trim() === "" &&
      article3.toString().trim() === ""
    ) {
      alert("Some of Article Field are empty");
    } else {
      createArtical({
        variables: {
          Cid: Cid,
          editor: editor,
          title: title,
          subTitle: subTitle,
          addition: addition,
          article1: article1,
          article2: article2,
          article3: article3,
        },
      })
        .then((res) => {
          const data = res?.data?.createArtical;

          console.log("SHOW SOMETHING", res);
          if (data !== null) {
            setLastArticleId(data.id);

            alert("Added Successfully");
            window.scroll(0, 0);
            setSubTitle("");
            setEditor("");
            setTitle("");
            setUploadImg1("");
            setUploadImg2("");
            setAddition("");
            setArticle1("");
            setArticle2("");
            setArticle3("");
            setArticle4("");
            setArticle5("");
            setArticle6("");
            setImge1("");
            setImge2("");
            setImge3("");
            setImge4("");
            setImge5("");
            setImge6("");
            setDate("");
          } else {
            alert(data?.data?.errors[0].message);
          }
        })
        .catch((error) => {
          console.error("ERROR", error);
        });
    }
  };

  const ClearInputs = (e) => {
    e.preventDefault();
    document.getElementById("addition").value = "";
    document.getElementById("editor").value = "";
    document.getElementById("date").value = "";
    document.getElementById("title").value = "";
    document.getElementById("subTitle").value = "";
    document.getElementById("UploadImage1").src = "";
    document.getElementById("UploadImage2").src = "";
    setSubTitle("");
    setEditor("");
    setTitle("");
    setUploadImg1("");
    setUploadImg2("");
    setAddition("");
    setArticle1("");
    setArticle2("");
    setArticle3("");
    setArticle4("");
    setArticle5("");
    setArticle6("");
    setImge1("");
    setImge2("");
    setImge3("");
    setImge4("");
    setImge5("");
    setImge6("");
    setDate("");
    document.getElementById("article4").value = "";
    document.getElementById("article5").value = "";
    document.getElementById("article6").value = "";
    document.getElementById("imge1").src = "";
    document.getElementById("imge2").src = "";
    document.getElementById("imge3").src = "";
    document.getElementById("imge4").src = "";
    document.getElementById("imge5").src = "";
    document.getElementById("imge6").src = "";
  };

  // const UploadImage1 = async (e) => {
  //   e.persist();
  //   const config1 = {
  //     bucketName: "respect",
  //     dirName: `categories/${Ctl}/titleImage`,
  //     ACL: "public-read",
  //     region: "ap-south-1",
  //     accessKeyId: "AKIAR5OK4LXS37SFPQEL",
  //     secretAccessKey: "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",
  //   };

  //   try {
  //     await S3FileUpload.uploadFile(e.target.files[0], config1)
  //       .then((data) => {
  //         const img1 = data.location;
  //         setUploadImg1(img1);
  //         Submit(img1);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } catch (error) {
  //     console.log("error while uploading image", error);
  //   }
  // };

  const UploadImage2 = (e) => {
    e.persist();
    const config2 = {
      bucketName: "respect",
      dirName: `categories/${Ctl}/subTitleImage`,
      ACL: "public-read",
      region: "ap-south-1",
      accessKeyId: "AKIAR5OK4LXS37SFPQEL",
      secretAccessKey: "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",
    };

    try {
      S3FileUpload.uploadFile(e.target.files[0], config2)
        .then((data) => {
          const img2 = data.location;
          setUploadImg2(img2);
          Submit(img2);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log("error while uploading image", error);
    }
  };

  async function handClickSendNewsletter() {
    try {
      if (lastArticleId === null) return undefined;

      const response = await sendNewsletter({
        variables: { articleId: lastArticleId },
      });

      if (response.data.success === false) {
        alert("Error occurred while try to send newsletters");
        return undefined;
      }

      setLastArticleId(null);
      alert("Newsletters successfully sent.");
    } catch (error) {
      if (error instanceof ApolloError)
        alert("Error occurred while try to send newsletters");
    }
  }

  return (
    <div className="new_article">
      <div className="new_article_container">
        <Form className="container_form">
          <Form.Group
            as={Row}
            controlId="formPlaintextEmail"
            className="form_row"
          >
            <Form.Label column sm="2" className="form_label">
              Addition
            </Form.Label>
            <Col sm="10">
              <Form.Control
                className="form_control"
                id="addition"
                value={addition}
                onChange={(e) => setAddition(e.target.value)}
                type="number"
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            controlId="formPlaintextEmail"
            className="form_row"
          >
            <Form.Label column sm="2" className="form_label">
              Editor
            </Form.Label>
            <Col sm="10">
              <Form.Control
                className="form_control"
                id="editor"
                type="text"
                value={editor}
                onChange={(e) => setEditor(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            controlId="formPlaintextEmail"
            className="form_row"
          >
            <Form.Label column sm="2" className="form_label">
              Date
            </Form.Label>
            <Col sm="10" style={{ display: "flex" }}>
              <DatePicker
                className="datePickerStyle form_control"
                selected={date}
                onChange={(e) => setDate(e)}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            controlId="formPlaintextEmail"
            className="form_row"
          >
            <Form.Label column sm="2" className="form_label">
              Title
            </Form.Label>
            <Col sm="10">
              <Form.Control
                as="textarea"
                className="form_control_title"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                rows={3}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            controlId="formPlaintextEmail"
            className="form_row"
          >
            <Form.Label column sm="2" className="form_label">
              Sub Title
            </Form.Label>
            <Col sm="10">
              <Form.Control
                as="textarea"
                className="form_control_title"
                id="subTitle"
                value={subTitle}
                onChange={(e) => setSubTitle(e.target.value)}
                rows={3}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            controlId="formPlaintextEmail"
            className="form_row"
          >
            <Form.Label column sm="2" className="form_label">
              Image
            </Form.Label>
            <Col sm="10" className="form_img_row">
              {/* <label
                className="form_control_image"
                type="file"
                style={{ fontSize: "32px" }}
              >
                <input
                  id="file-input"
                  type="file"
                  onChange={(e) => {
                    let m = e.target.files[0];
                    console.log("check", m);
                    setUploadImg1(m);
                    UploadImage1(e);
                  }}
                />
                {uploadImg1 === null ? (
                  <i className="img">{Image}</i>
                ) : (
                  <img
                    className="form_control_image__uploaded1"
                    id="UploadImage1"
                    src={uploadImg1}
                    alt=""
                  />
                )}
              </label> */}
              <div>
                <label>Image Url Generator</label>
                <label className="form_control_imagee" type="file">
                  <input
                    id="file-input"
                    type="file"
                    onChange={(e) => {
                      let m1 = e.target.files[0];
                      console.log("check", m1);
                      setUploadImg2(m1);
                      UploadImage2(e);
                    }}
                  />
                  {uploadImg2 === null ? (
                    <i className="img">{Image}</i>
                  ) : (
                    <img
                      className="form_control_image__uploaded1e"
                      id="UploadImage2"
                      src={uploadImg2}
                      value={uploadImg2}
                      alt=""
                    />
                  )}
                </label>
                <TextField
                  className="findUrl"
                  variant="outlined"
                  label="URL"
                  fullWidth
                  style={{ marginTop: 10, marginBottom: 15 }}
                  value={uploadImg2}
                />
              </div>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            controlId="formPlaintextEmail"
            className="form_row"
          >
            <Form.Label column sm="2" className="form_label">
              Article
            </Form.Label>
            <Col sm="10">
              <div className="article_slots">
                <Col sm="10">
                  <div className="form_control_atl">
                    <label>Main paragraph</label>
                    {/* <Editor
                      editorState={article1}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={(e) => setArticle1(e)}
                    /> */}
                    <SunEditor
                      placeholder="Please type here..."
                      setDefaultStyle="font-family: arial; font-size: 12pt;line-height: 1.5;text-align:left;"
                      name="Title para"
                      setOptions={sunEdidtorOption}
                      defaultValue={article1}
                      onChange={(e) => {
                        setArticle1(e);
                      }}
                    />
                    {/* <div>
                      <Button
                        variant="contained"
                        onClick={(e) => {
                          e.preventDefault();
                          setArticle1("");
                        }}
                      >
                        Clear main Paragraph
                      </Button>
                    </div> */}
                  </div>
                </Col>
                <Col sm="10">
                  <div className="form_control_atl">
                    <label>2nd Main paragraph</label>
                    {/* <Editor
                      editorState={article2}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={(e) => setArticle2(e)}
                    /> */}
                    <SunEditor
                      placeholder="Please type here..."
                      setDefaultStyle="font-family: arial; font-size: 12pt;line-height: 1.5;text-align:left;"
                      name="Title para"
                      setOptions={sunEdidtorOption}
                      onChange={(e) => setArticle2(e)}
                    />
                    {/* <div>
                      <Button
                        variant="contained"
                        onClick={(e) => {
                          e.preventDefault();
                          setArticle2("");
                        }}
                      >
                        Clear 2nd main Paragraph
                      </Button>
                    </div> */}
                  </div>
                </Col>
                <Col sm="10">
                  <div className="form_control_atl">
                    <label>Article paragraph</label>
                    {/* <Editor
                      editorState={article3}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={(e) => setArticle3(e)}
                    /> */}
                    <SunEditor
                      placeholder="Please type here..."
                      setDefaultStyle="font-family: arial; font-size: 12pt;line-height: 1.5;text-align:left;"
                      name="Title para"
                      setOptions={sunEdidtorOption}
                      onChange={(e) => setArticle3(e)}
                    />
                    {/* <div>
                      <Button
                        variant="contained"
                        onClick={(e) => {
                          e.preventDefault();
                          setArticle3("");
                        }}
                      >
                        Clear Article Paragraph
                      </Button>
                    </div> */}
                  </div>
                </Col>
              </div>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            controlId="formPlaintextEmail"
            className="form_row"
          >
            <Form.Label column sm="2" className="form_label" />
            <Col sm="10" className="btns">
              <Button
                disabled={!lastArticleId}
                type="button"
                color="primary"
                variant="contained"
                disableElevation
                style={{
                  marginRight: "32px",
                  borderRadius: "999px",
                  textTransform: "none",
                }}
                onClick={handClickSendNewsletter}
              >
                Send Newsletter
              </Button>
              <button className="btn_save" onClick={Submit}>
                Save
              </button>
              <button className="form__btn_clear" onClick={ClearInputs}>
                Clear
              </button>
            </Col>
          </Form.Group>
        </Form>
      </div>
      {/* <TestMutation GET_DOGS={FILMS_QUERY} />; */}
    </div>
  );
}

export default ArticleForm;
