import axios from "axios";

import { SERVER_URL } from "./URLs";

/** @type{import('axios').AxiosRequestConfig} */
const options = {
  baseURL: SERVER_URL,
  method: "POST",
};

const instance = axios.create(options);

export default instance;
