import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../css/Article.css";
import axios from "../libraries/axios";
import AuthContext from "../libraries/context.js";
import ArticleEditForm from "../pages/ArticleEditForm";
import NavbarE from "../pages/NavbarE";

function Article() {
  const context = useContext(AuthContext);
  const token = context.user.token;
  const location = useLocation();
  let cName = location?.state?.title;
  let cId = location?.state?.id;

  const [addition, setAddition] = useState("");
  const [editor, setEditor] = useState("");
  const [date, setDate] = useState("");
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [para1, setPara1] = useState("");
  const [para2, setPara2] = useState("");
  const [para3, setPara3] = useState("");
  const [para4, setPara4] = useState("");
  const [para5, setPara5] = useState("");
  const [para6, setPara6] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
  const [image5, setImage5] = useState("");
  const [image6, setImage6] = useState("");
  const [editUploadImg1, setEditUploadImg1] = useState("");
  const [editUploadImg2, setEditUploadImg2] = useState("");
  const [category, setCategory] = useState([]);
  const [navLinks, setNavLinks] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const [refreshme, setRefreshme] = useState(true);

  const [isNewsletterSent, setIsNewsletterSent] = useState(false);
  const [selectedArticleId, setSelectedArticleId] = useState(null);

  useEffect(() => {
    async function fetchArticle(articleId) {
      const response = await axios({
        method: "POST",
        data: {
          query: `query {
            artical(where: { id: "${articleId}" }) {
              isNewsletterSend
            }
          }`,
        },
      });

      if (response.data.errors) {
        alert("failed to get newsletter information of this article.");
        return undefined;
      }

      if (!response.data?.data?.artical) {
        alert("article not found");
        return undefined;
      }

      setIsNewsletterSent(response.data.data.artical.isNewsletterSend);
    }

    if (selectedArticleId) {
      fetchArticle(selectedArticleId);
    }
  }, [selectedArticleId]);

  useEffect(() => {
    FetchCategory();
  }, []);

  const FetchCategory = async () => {
    try {
      setVisibility(true);
      await axios({
        method: "POST",

        data: {
          query: `query {
            categories {
              id
              category
              isActive
            }
          }
          `,
        },
        headers: {
          Accept: `application/json`,
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          const data = res.data;
          if (data != null) {
            const updateCategory = data.data.categories;
            const isActiveTrueFiles = updateCategory?.filter(
              (item) => item.isActive === "true"
            );
            const updateHello = isActiveTrueFiles?.map((item) => {
              return {
                id: item.id,
                link: `/articals/${`${item.category}`.toLowerCase()}`,
                title: `${item.category}`,
              };
            });
            setNavLinks(updateHello);
            setCategory(isActiveTrueFiles);
          } else {
            alert(res.data.errors[0].message);
          }
          setVisibility(false);
        })
        .catch((error) => {
          console.error("Category FetcingError2 ", error);
          setVisibility(false);
        });
    } catch (err) {
      console.log("CategoryError2", err);
      setVisibility(false);
    }
  };

  const Delete = () => {
    var r = window.confirm("Do you want to delete this category?");
    if (r === true) {
      try {
        axios({
          method: "POST",

          data: {
            query: `mutation{
              deleteCategoryCompletly(where: { id: "${cId}" }) {
                id
              }
          }
          `,
          },
          headers: {
            Accept: `application/json`,
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            if (res.data.data.deleteCategory !== null) {
              console.log(
                "Delete category issue tell me whats that",
                res.data.data
              );
              setRefreshme(false);
              setTimeout(() => {
                FetchCategory();
                setRefreshme(true);
                cName = undefined;
                cId = undefined;
              }, [1000]);
            } else {
              alert(res.data.errors[0].message);
            }
          })
          .catch((error) => {
            console.error("Category delete error ", error);
          });
      } catch (err) {
        console.log("err", err);
      }
    } else {
      console.log("cancled");
    }
  };

  return (
    refreshme && (
      <div className="article">
        <NavbarE
          category={category}
          setCategory={setCategory}
          navLinks={navLinks}
          FetchCategory={FetchCategory}
          setNavLinks={setNavLinks}
          visibility={visibility}
          setVisibility={setVisibility}
          addition={addition}
          editor={editor}
          date={date}
          title={title}
          subTitle={subTitle}
          para1={para1}
          para2={para2}
          para3={para3}
          para4={para4}
          para5={para5}
          para6={para6}
          image1={image1}
          image2={image2}
          image3={image3}
          image4={image4}
          image5={image5}
          image6={image6}
          editUploadImg1={editUploadImg1}
          editUploadImg2={editUploadImg2}
          setAddition={setAddition}
          setEditor={setEditor}
          setDate={setDate}
          setTitle={setTitle}
          setSubTitle={setSubTitle}
          setPara1={setPara1}
          setPara2={setPara2}
          setPara3={setPara3}
          setPara4={setPara4}
          setPara5={setPara5}
          setPara6={setPara6}
          setImage1={setImage1}
          setImage2={setImage2}
          setImage3={setImage3}
          setImage4={setImage4}
          setImage5={setImage5}
          setImage6={setImage6}
          setEditUploadImg1={setEditUploadImg1}
          setEditUploadImg2={setEditUploadImg2}
        />

        {cId !== undefined && cName !== undefined ? (
          <div className="article_left">
            <div className="article_left_row">
              <span className="article_heading">{cName}</span>
              <button className="btn_dlt" onClick={() => Delete()}>
                Delete
              </button>

              {isNewsletterSent && (
                <div
                  style={{
                    marginLeft: ".25em",
                    fontSize: "0.625rem",
                    backgroundColor: "#ff2c2c",
                    color: "white",
                    paddingInline: ".5em",
                    paddingBlock: ".25em",
                    borderRadius: "999px",
                  }}
                >
                  Newsletter already send
                </div>
              )}
            </div>
            <ArticleEditForm
              cName={cName}
              cId={cId}
              setSelectedArticleId={setSelectedArticleId}
              selectedArticleId={selectedArticleId}
            />
          </div>
        ) : (
          <div style={{ flex: 0.72 }}>Please Select the Category</div>
        )}
      </div>
    )
  );
}

export default Article;
