import React from "react";
import "../css/Home.css";
import LeftSlots from "../pages/LeftSlots";
import RightSlots from "../pages/RightSlots";

function Home() {
  return (
    <div className="home">
      <RightSlots />
      <LeftSlots />
    </div>
  );
}

export default Home;
