import React, { useContext,useState } from "react";

import "../css/HeadingSlots.css";
import AuthContext from "../libraries/context.js";
import axios from "../libraries/axios";

function HeadSlot({ slot, addition, allCategroy, make }) {
  const context = useContext(AuthContext);
  const token = context.user.token;
  const [volumeDate, setVolumeDate] = useState(null);
  const [saveMe, setSaveMe] = useState(null);

  const Save = (e) => {
    e.preventDefault();
    if (saveMe) {
      try {
        axios({
          method: "POST",
          data: {
            query: `
              mutation {
                updateSlot(
                  where: {
                        id: "${slot?.id}"
                }
                  data: { artical: { connect: { id: "${saveMe?.aId}" } }
                  place:${make}
                  slot:"${slot?.slot}"
                }
                ) {
                  id
                }
              }
            `,
          },
          headers: {
            Accept: `application/json`,
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            const data = res.data.data.updateSlot;
            if (data != null) {
              alert("Updated Successfully");
            } else {
              alert(res.data.errors[0].message);
            }
          })
          .catch((error) => {
            console.error("FetcingError while updating slots ", error);
          });
      } catch (err) {
        console.log("Error", err);
      }
    } else {
      alert("Please select Addition and Topic");
    }
  };

  const Cancel = (e) => {
    e.preventDefault();
    setSaveMe(null);
  };

  const selectVolume = (i) => {
    if (i === "Addition") {
      alert("Select Topic or there are no more topic for choosed category");
      setSaveMe(null);
    } else {
      setSaveMe(JSON.parse(i));
    }
  };
  const selectMake = (e) => {
    const letMake = JSON.parse(e.target.value);
    const newAllarticle = addition?.filter(
      (item) => item?.cName === letMake?.category
    );
    setVolumeDate(newAllarticle);
  };
  return (
    <>
      <div className="spans">
        <span className="topic">{slot?.slot}</span>
        <span>
          <select
            className="dropdown-basic"
            id="dropdown-basic"
            onChange={(e) => selectMake(e)}
          >
            <option>Category</option>
            {allCategroy?.map((p) => {
              if (slot?.artical) {
                if (p?.id === slot?.artical?.categoryId) {
                  return (
                    <option
                      className="categoryOption"
                      value={JSON.stringify(p)}
                      selected
                    >
                      {p?.category}
                    </option>
                  );
                } else {
                  return (
                    <option
                      className="categoryOption"
                      value={JSON.stringify(p)}
                    >
                      {p?.category}
                    </option>
                  );
                }
              } else {
                return (
                  <option className="categoryOption" value={JSON.stringify(p)}>
                    {p?.category}
                  </option>
                );
              }
            })}
          </select>
        </span>
        <span>
          <select
            className="dropdown-basic"
            id="dropdown-basic2"
            onClick={() => {
              if (volumeDate === null) {
                alert("Please Select Category First");
              }
            }}
            onChange={(e) => {
              selectVolume(e.target.value);
            }}
          >
            <option>Addition </option>
            {volumeDate === null ? (
              <option
                className="categoryOption"
                value={JSON.stringify(slot?.artical)}
                selected
              >
                {slot?.artical?.title}
              </option>
            ) : (
              volumeDate?.map((i) => {
                if(i?.cId)
                return (
                  <option className="categoryOption" value={JSON.stringify(i)}>
                    {i?.title}
                  </option>
                );
              })
            )}
            );
          </select>
        </span>
      </div>
      <div className="btns_cs">
        <button className="cancel" onClick={(e) => Cancel(e)}>
          Cancel
        </button>
        <button
          className="save"
          style={
            saveMe !== null
              ? { background: "black", color: "white" }
              : {
                  background: "#dbd6d6",
                  color: "white",
                  borderColor: "#dbd6d6",
                }
          }
          onClick={(e) => Save(e)}
          disabled={saveMe !== null ? false : true}
        >
          Save
        </button>
      </div>
    </>
  );
}

export default HeadSlot;
