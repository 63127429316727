import React, { useContext, useEffect, useState } from "react";

import "../css/NewArticles.css";
import Navbar from "../pages/Navbar";
import ArticleForm from "../pages/ArticleForm";
import { useLocation } from "react-router-dom";
// import AuthContext from "../libraries/context.js";

function NewArticles() {
  const location = useLocation();
  const Cid = location?.state?.id;
  const Ctl = location?.state?.title;

  const [addition, setAddition] = useState("");
  const [editor, setEditor] = useState("");
  const [date, setDate] = useState(new Date());
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [article1, setArticle1] = useState("");
  const [article2, setArticle2] = useState("");
  const [article3, setArticle3] = useState("");
  const [article4, setArticle4] = useState("");
  const [article5, setArticle5] = useState("");
  const [article6, setArticle6] = useState("");
  const [imge1, setImge1] = useState("");
  const [imge2, setImge2] = useState("");
  const [imge3, setImge3] = useState("");
  const [imge4, setImge4] = useState("");
  const [imge5, setImge5] = useState("");
  const [imge6, setImge6] = useState("");
  const [uploadImg1, setUploadImg1] = useState("");
  const [uploadImg2, setUploadImg2] = useState("");
  const [refreshMe, setRefresMe] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setRefresMe(true);
    }, 500);
  }, [refreshMe]);
  return (
    <div className="na">
      <Navbar
        setRefresMe={setRefresMe}
        addition={addition}
        editor={editor}
        date={date}
        title={title}
        subTitle={subTitle}
        article1={article1}
        article2={article2}
        article3={article3}
        article4={article4}
        article5={article5}
        article6={article6}
        imge1={imge1}
        imge2={imge2}
        imge3={imge3}
        imge4={imge4}
        imge5={imge5}
        imge6={imge6}
        uploadImg1={uploadImg1}
        uploadImg2={uploadImg2}
        setAddition={setAddition}
        setEditor={setEditor}
        setDate={setDate}
        setTitle={setTitle}
        setSubTitle={setSubTitle}
        setArticle1={setArticle1}
        setArticle2={setArticle2}
        setArticle3={setArticle3}
        setArticle4={setArticle4}
        setArticle5={setArticle5}
        setArticle6={setArticle6}
        setImge1={setImge1}
        setImge2={setImge2}
        setImge3={setImge3}
        setImge4={setImge4}
        setImge5={setImge5}
        setImge6={setImge6}
        setUploadImg1={setUploadImg1}
        setUploadImg2={setUploadImg2}
      />
      {Cid !== undefined && Ctl !== undefined && refreshMe ? (
        <div className="d_left">
          <span className="na_heading">{Ctl}</span>
          <ArticleForm
            Cid={Cid}
            Ctl={Ctl}
            addition={addition}
            editor={editor}
            date={date}
            title={title}
            subTitle={subTitle}
            article1={article1}
            article2={article2}
            article3={article3}
            article4={article4}
            article5={article5}
            article6={article6}
            imge1={imge1}
            imge2={imge2}
            imge3={imge3}
            imge4={imge4}
            imge5={imge5}
            imge6={imge6}
            uploadImg1={uploadImg1}
            uploadImg2={uploadImg2}
            setAddition={setAddition}
            setEditor={setEditor}
            setDate={setDate}
            setTitle={setTitle}
            setSubTitle={setSubTitle}
            setArticle1={setArticle1}
            setArticle2={setArticle2}
            setArticle3={setArticle3}
            setArticle4={setArticle4}
            setArticle5={setArticle5}
            setArticle6={setArticle6}
            setImge1={setImge1}
            setImge2={setImge2}
            setImge3={setImge3}
            setImge4={setImge4}
            setImge5={setImge5}
            setImge6={setImge6}
            setUploadImg1={setUploadImg1}
            setUploadImg2={setUploadImg2}
          />
        </div>
      ) : (
        <div style={{ flex: 0.72 }}>Please Select the Category</div>
      )}
    </div>
  );
}

export default NewArticles;
