import gql from 'graphql-tag';

export const UPDATE_ARTICLE = gql`
  mutation UpdateArtical(
    $id: String!
    $editor: String!
    $title: String!
    $subTitle: String!
    $addition: String!
    $article1: String!
    $article2: String!
    $article3: String!
  ) {
    updateArtical(
      where: { id: $id }
      data: {
        editer: $editor
        title: $title
        subTitle: $subTitle
        titleImage: ""
        volume: $addition
        pharagraph01: $article1
        pharagraph02: $article2
        pharagraph03: $article3
      }
    ) {
      id
    }
  }
`;
export const CREATE_ARTICLE = gql`
  mutation CreateArtical(
    $Cid: String!
    $editor: String!
    $title: String!
    $subTitle: String!
    $addition: String!
    $article1: String!
    $article2: String!
    $article3: String!
  ) {
    createArtical(
      data: {
        category: { connect: { id: $Cid } }
        editer: $editor
        title: $title
        titleImage: ""
        subTitle: $subTitle
        volume: $addition
        pharagraph01: $article1
        pharagraph02: $article2
        pharagraph03: $article3
      }
    ) {
      id
    }
  }
`;

const SEND_NEWSLETTER = gql`
  mutation sendNewsletter($articleId: String!) {
    sendNewsletter(where: { articleId: $articleId }) {
      success
    }
  }
`;

export { SEND_NEWSLETTER };
