import React, { useState, useContext } from "react";
import "../css/SlotCard1.css";
import { Card, Button } from "react-bootstrap";
import { uuid } from "uuidv4";
import img1 from "../images/img1.png";
import img2 from "../images/img2.png";

function SlotCard1() {
  const [mainSlot, setMainSlot] = useState([
    {
      sId: uuid(),
      image: img1,
      slot: "Slot 01",
      tittle: "Sed ut perspiciatis unde omnis",
      TitlePara:
        "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque orro quisquam est, qui dolorem ipsum quia dolor sit amet.",
    },
    {
      sId: uuid(),
      image: img2,
      slot: "Slot 02",
      tittle: "Sed ut perspiciatis unde omnis",
      TitlePara:
        "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque orro quisquam est, qui dolorem ipsum quia dolor sit amet.",
    },
  ]);

  return (
    <div className="slot">
      {mainSlot?.map((item) => {
        return (
          <Card>
            <div className="box">
              <Card.Img className="img" src={item.image} />
              <div className="innerBox">
                <Button className="btn_slot">{item.slot}</Button>
              </div>
            </div>

            <Card.Body className="body">
              <Card.Title className="title">{item.tittle}</Card.Title>
              <Card.Text className="text">{item.TitlePara}</Card.Text>
            </Card.Body>
          </Card>
        );
      })}
    </div>
  );
}

export default SlotCard1;
