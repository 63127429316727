import React, { useState, useContext } from "react";
import "../css/Login.css";
import imgLog from "../images/Respect logo white 1.png";
import { auth } from "../libraries/firebase";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import AuthContext from "../libraries/context";

function Login() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const context = useContext(AuthContext);

  let schema = yup.object().shape({
    email: yup.string(),
  });

  const SignIn = async (e) => {
    e.preventDefault();
    if (email.trim().length === 0 || password.trim().length === 0) {
      return;
    }
    schema
      .validate({ email: email })
      .then(async () => {
        try {
          await auth
            .signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
              var AmUser =
                userCredential.user.toJSON().stsTokenManager.accessToken;
              window.localStorage.setItem("token", AmUser);
              context.setUser({
                token: AmUser,
              });

              history.push("/home");
            })
            .catch((error) => {
              console.log("error", error);
              alert(error.message);
            });
        } catch (error) {
          //  alert(error.errors[0] + "0000");
          console("try catch error", error.errors[0]);
        }
      })
      .catch(function (err) {
        alert(err.email);
      });
  };

  return (
    <div className="login">
      <div className="login-container">
        <form onSubmit={(e) => SignIn(e)}>
          <img src={imgLog} className="logo-image" />
          <h3 className="login-heading">Login here</h3>
          <div className="login-form">
            <label className="login-label">Email</label>
            <input
              type="text"
              className="login-input"
              placeholder="sam.info@gmail.com"
              style={{ color: "white" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label className="login-label">Password</label>
            <input
              type="text"
              className="login-input"
              placeholder="***********"
              style={{ color: "white" }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="button-container">
              {/* <Link to="/home" className="login-button"> */}
              <button className="login-button">Login</button>
              {/* </Link> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
