import React, { useContext, useEffect, useState } from "react";
import "../css/Navbar.css";
import AuthContext from "../libraries/context.js";
import axios from "../libraries/axios";
import { useHistory } from "react-router-dom";
import Loader from "./Loader.js";

function Navbar({
  setRefresMe,
  setAddition,
  setEditor,
  setDate,
  setTitle,
  setSubTitle,
  setArticle1,
  setArticle2,
  setArticle3,
  setArticle4,
  setArticle5,
  setArticle6,
  setImge1,
  setImge2,
  setImge3,
  setImge4,
  setImge5,
  setImge6,
  setUploadImg1,
  setUploadImg2,
}) {
  const [category2, setCategory2] = useState([]);
  const history = useHistory();
  const context = useContext(AuthContext);
  const token = context.user.token;
  const [navLinks2, setNavLinks2] = useState([]);
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    FetchCategory2();
  }, []);

  const FetchCategory2 = async () => {
    try {
      setVisibility(true);
      await axios({
        method: "POST",
        data: {
          query: `query {
            categories {
              id
              category
              isActive
            }
          }
          `,
        },
        headers: {
          Accept: `application/json`,
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          const data = res.data;
          if (data != null) {
            const updateCategory2 = data.data.categories;
            const filterIsActive = updateCategory2?.filter(
              (item) => item?.isActive === "true"
            );
            const updateHello2 = filterIsActive?.map((item) => {
              return {
                id: item.id,
                link: `/newarticals/${`${item.category}`.toLowerCase()}`,
                title: `${item.category}`,
              };
            });
            setNavLinks2(updateHello2);
            setCategory2(filterIsActive);
          } else {
            alert(res.data.errors[0].message);
          }
          setVisibility(false);
        })
        .catch((error) => {
          console.error("Category FetcingError ", error);
          setVisibility(false);
        });
    } catch (err) {
      console.log("CategoryError", err);
      setVisibility(false);
    }
  };
  const Goto = (id, title, link) => {
    setRefresMe(false);
    history.push({
      pathname: link,
      state: {
        id: id,
        title: title,
      },
    });
    setSubTitle("");
    setEditor("");
    setTitle("");
    setUploadImg1("");
    setUploadImg2("");
    setAddition("");
    setArticle1("");
    setArticle2("");
    setArticle3("");
    setArticle4("");
    setArticle5("");
    setArticle6("");
    setImge1("");
    setImge2("");
    setImge3("");
    setImge4("");
    setImge5("");
    setImge6("");
    setDate("");
  };

  return (
    <div className="navbar">
      <div className="nav">
        <Loader visibility={visibility} />
        <div className="nav_items">
          {navLinks2?.map((item) => {
            return (
              <span
                className="span"
                onClick={() => Goto(item.id, item.title, item.link)}
              >
                {item.title}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
