import React, { useContext, useEffect, useState } from "react";
import "../css/LeftSlots.css";
import HeadingSlots from "./HeadingSlots";
import HeadingSlots2 from "./HeadingSlots2";
import HeadingSlots3 from "./HeadingSlots3";
import AuthContext from "../libraries/context.js";
import axios from "../libraries/axios";
import * as Slots from "../libraries/Slots";

function LeftSlots() {
  const context = useContext(AuthContext);
  const token = context.user.token;
  const [visibility, setVisibility] = useState(false);
  const [slots, setSlots] = useState([]);

  const [slotsM, setSlotsM] = useState([]);
  const [slotsS, setSlotsS] = useState([]);
  const [slotsT, setSlotsT] = useState([]);
  const [allCategroy, setAllCategory] = useState();
  useEffect(() => {
    FetchSlots();
    FetchCategory();
  }, []);
  const FetchCategory = () => {
    try {
      axios({
        method: "POST",
        data: {
          query: `
          query {
            categories {
              id
              category
              isActive
            }
          }          
          `,
        },
        headers: {
          Accept: `application/json`,
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          const data = res.data.data.categories;
          if (data != null) {
            const updateCategroy = data?.filter(
              (item) => item?.isActive === "true"
            );
            setAllCategory(updateCategroy);
          } else {
            alert(res.data.errors[0].message);
          }
        })
        .catch((error) => {
          console.error("FetcingError while updating slots ", error);
        });
    } catch (err) {
      console.log("Error", err);
    }
  };

  const FetchSlots = async () => {
    try {
      setVisibility(true);
      await axios({
        method: "POST",

        data: {
          query: `query {
            slots {
              id
              slot
              place
              artical{
                id
                categoryId
                categoryName
                slotId
                title
                subTitle
                editer
                volume
                main
                slotNo
                homePlace
                updatedAt
              }
            }
          }
          `,
        },
        headers: {
          Accept: `application/json`,
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          const data = res.data.data.slots;
          if (data != null) {
            const updateSlots = data;
            const mainFilter = updateSlots?.filter(
              (item) =>
                item?.place === "MAIN" &&
                (item?.slot === Slots?.mainSlot1 || item?.slot === Slots?.mainSlot2)
            );
            setSlotsM(
              mainFilter?.sort((a, b) => parseInt(a?.slot) - parseInt(b?.slot))
            ); // done
            const secondFilter = updateSlots?.filter(
              (item) =>
                item?.place === "SECOND" &&
                ( item?.slot === Slots?.secondSlot1 ||
                  item?.slot === Slots?.secondSlot2 ||
                  item?.slot === Slots?.secondSlot3 ||
                  item?.slot === Slots?.secondSlot4 ||
                  item?.slot === Slots?.secondSlot5)
            );
            console.log("second", secondFilter);
            setSlotsS(secondFilter);
            const thirdFilter = updateSlots?.filter(
              (item) =>
                item?.place === "THIRD" &&
                ( item?.slot === Slots?.thirdSlot1 ||
                  item?.slot === Slots?.thirdSlot2 ||
                  item?.slot === Slots?.thirdSlot3 ||
                  item?.slot === Slots?.thirdSlot4 ||
                  item?.slot === Slots?.thirdSlot5 ||
                  item?.slot === Slots?.thirdSlot6 ||
                  item?.slot === Slots?.thirdSlot7)
            );
            setSlotsT(
              thirdFilter?.sort((a, b) => parseInt(a?.slot) - parseInt(b?.slot))
            );
          } else {
            alert(res.data.errors[0].message);
          }
          setVisibility(false);
        })
        .catch((error) => {
          console.error("slots FetcingError ", error);
          setVisibility(false);
        });
    } catch (err) {
      console.log("slotsError", err);
      setVisibility(false);
    }
  };

  return (
    <div className="left_solts">
      <div className="left_head">
        <span>Slots</span>
        <span>Categroy</span>
        <span className="span3">Topic</span>
      </div>
      <fieldset className="heading_slots">
        <legend className="legend">Main Heading Slots</legend>
        <HeadingSlots
          visibility={visibility}
          setVisibility={setVisibility}
          slots={slotsM}
          setSlots={setSlots}
          allCategroy={allCategroy}
          make="MAIN"
        />
      </fieldset>
      <fieldset className="heading_slots">
        <legend className="legend">2nd Heading Slots</legend>
        <HeadingSlots2
          slots={slotsS}
          visibility={visibility}
          setVisibility={setVisibility}
          allCategroy={allCategroy}
          make="SECOND"
        />
      </fieldset>
      <fieldset className="heading_slots">
        <legend className="legend">3rd Heading Slots</legend>
        <HeadingSlots3
          slots={slotsT}
          visibility={visibility}
          setVisibility={setVisibility}
          allCategroy={allCategroy}
          make="THIRD"
        />
      </fieldset>
      {/* <div className="btns_cs">
        <button className="cancel" onClick={(e) => Cancel(e)}>
          Cancel
        </button>
        <button className="save" onClick={(e) => Save(e)}>
          Save
        </button>
      </div> */}
    </div>
  );
}

export default LeftSlots;
