import Loader from "../pages/Loader.js";
import React, { useContext, useEffect, useState } from "react";

import "../css/HeadingSlots.css";
import AuthContext from "../libraries/context.js";
import axios from "../libraries/axios";
import HeadSlot from "../Componet/HeadSlot.jsx";

function HeadingSlots({ visibility, setVisibility, slots, allCategroy, make }) {
  const context = useContext(AuthContext);
  const token = context.user.token;
  const [addition, setAddition] = useState([]);

  useEffect(() => {
    FetchArticals();
  }, []);

  const FetchArticals = async () => {
    try {
      setVisibility(true);
      await axios({
        method: "POST",
        data: {
          query: `query {
            articals {
              id
              slotId
              title
              volume
              categoryName
              categoryId
            }
          }
          
          `,
        },
        headers: {
          Accept: `application/json`,
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          const data = res.data.data.articals;
          if (data != null) {
            const updateAddition = data?.map((item) => {
              return {
                volume: item.volume,
                id: item.slotId,
                aId: item.id,
                title: item.title,
                cName: item?.categoryName,
                cId: item?.categoryId,
              };
            });

            setAddition(updateAddition);
          } else {
            alert(res.data.errors[0].message);
          }
          setVisibility(false);
        })
        .catch((error) => {
          console.error("slots FetcingError ", error);
          setVisibility(false);
        });
    } catch (err) {
      console.log("slotsError", err);
      setVisibility(false);
    }
  };

  return (
    <div className="slotItem">
      <Loader visibility={visibility} />
      <div className="slot_items">
        {slots?.map((item) => {
          return (
            <HeadSlot
              slot={item}
              addition={addition}
              allCategroy={allCategroy}
              make={make}
            />
          );
        })}
      </div>
    </div>
  );
}

export default HeadingSlots;
