import React from "react";
import "../css/Loader.css";

function Loader({ visibility = false }) {
  return (
    <>
      {visibility === true ? (
        <div class="loading">Loading&#8230;</div>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default Loader;
