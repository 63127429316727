import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyB7vEIf8w9b0Ja7Q9kfjOWxWv0NacLEiNU",
  authDomain: "magazine-admin.firebaseapp.com",
  projectId: "magazine-admin",
  storageBucket: "magazine-admin.appspot.com",
  messagingSenderId: "446407155240",
  appId: "1:446407155240:web:4340ca284d598a7f101f1d",
};

//Initialize the App
const fireBaseApp = firebase.initializeApp(firebaseConfig);

//Initialize the db
const db = fireBaseApp.firestore();
const auth = firebase.auth();

//export db and auth
export { db, auth };
