import React, { useState } from "react";
import "../css/SlotCard2.css";
import { Card, Button } from "react-bootstrap";

function SlotCard2() {
  const [bottomSlot, setBottomSlot] = useState([
    {
      slot: "Slot 02",
      title: "Sed ut perspiciatis unde omnis",
      para: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi ciunt. Neque porro quisquam est.",
    },
    {
      slot: "Slot 03",
      title: "Sed ut perspiciatis unde omnis",
      para: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi ciunt. Neque porro quisquam est.",
    },
    {
      slot: "Slot 04",
      title: "Sed ut perspiciatis unde omnis",
      para: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi ciunt. Neque porro quisquam est.",
    },
    {
      slot: "Slot 05",
      title: "Sed ut perspiciatis unde omnis",
      para: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi ciunt. Neque porro quisquam est.",
    },
    {
      slot: "Slot 06",
      title: "Sed ut perspiciatis unde omnis",
      para: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi ciunt. Neque porro quisquam est.",
    },
    {
      slot: "Slot 07",
      title: "Sed ut perspiciatis unde omnis",
      para: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi ciunt. Neque porro quisquam est.",
    },
  ]);
  return (
    <div className="slotcard2">
      {bottomSlot?.map((item) => {
        return (
          <div className="card2" style={{ width: "250.76", height: "78.23" }}>
            <Button className="btn_slot2">{item.slot}</Button>
            <Card.Body className="body2">
              <Card.Title className="title2">{item.title}</Card.Title>
              <Card.Text className="text2">{item.para}</Card.Text>
              <button className="btn_learn_more">Learn more</button>
            </Card.Body>
          </div>
        );
      })}
    </div>
  );
}

export default SlotCard2;
