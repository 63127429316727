import React, { useContext, useEffect, useState } from "react";
import "../css/Users.css";
import { Table } from "react-bootstrap";
import AuthContext from "../libraries/context.js";
import axios from "../libraries/axios";
import Loader from "../pages/Loader.js";
import * as D from "../Componet/myDate";

function Users() {
  const context = useContext(AuthContext);
  const token = context.user.token;
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    FetchUsers();
  }, []);

  const [users, setUsers] = useState([]);

  const FetchUsers = async () => {
    try {
      setVisibility(true);
      await axios({
        method: "POST",
        data: {
          query: `query{
            users {
              id
              firstName
              lastName
              email
              address
              mobileNo
              isActive
              isSubscribe
              createdAt
              updatedAt
            }
          }`,
        },
        headers: {
          Accept: `application/json`,
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          console.info("User Fetching Response ", res.data);
          const data = res.data.data.users;
          if (data != null) {
            const updateUser = data;
            setUsers(updateUser);
          } else {
            alert(res.data.errors[0].message);
          }
          setVisibility(false);
        })
        .catch((error) => {
          console.error("Users FetcingError ", error);
          setVisibility(false);
        });
    } catch (err) {
      console.log("UsersError", err);
      setVisibility(false);
    }
  };
  const sendEmail = async(e) => {
    e.preventDefault();
    try {
      setVisibility(true);
      await axios({
        method: "POST",
        data: {
          query: `query {
            sendEmail {
              success
            }
          }
          `,
        },
        headers: {
          Accept: `application/json`,
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          console.info("User Fetching Response ", res.data);

          setVisibility(false);
        })
        .catch((error) => {
          console.error("Users FetcingError ", error);
          setVisibility(false);
        });
    } catch (err) {
      console.log("UsersError", err);
      setVisibility(false);
    }
  };
  return (
    <div className="users">
      <div className="users__Container">
        <Loader visibility={visibility} />
        <Table className="users__ContainerTable">
          <thead>
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Registration Date</th>
              <th>Subscription</th>
            </tr>
          </thead>
          <tbody>
            {users?.map((item) => {
              return (
                <tr>
                  <td>{item.firstName}</td>
                  <td>{item.address}</td>
                  <td>{item.mobileNo}</td>
                  <td>{item.email}</td>
                  <td>{D.MinToDate(item.createdAt)}</td>
                  <td
                    style={
                      item.isActive === true
                        ? { color: "green" }
                        : { color: "red" }
                    }
                  >
                    {item.isActive ? "Active" : "Inactive"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          padding: "0 10%",
        }}
      >
        <button onClick={(e) => sendEmail(e)} className="sendEmail">
          Send Email
        </button>
      </div>
    </div>
  );
}

export default Users;
