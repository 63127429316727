import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./pages/Header";
import PageButtons from "./pages/PageButtons";
import NewArticles from "./navs/NewArticles";
import Article from "./navs/Article";
import Home from "./navs/Home";
import Login from "./navs/Login";
import Users from "./navs/Users";
import AuthContext from "./libraries/context.js";
import axios from "./libraries/axios";
import ClearContext from "./libraries/ClearContext";
import { GenToken } from "./libraries/GenToken";

function App() {
  const [user, setUser] = useState();
  const [clear, setClear] = useState("");

  const [navLinks, setNavLinks] = useState([]);
  const [navLinks2, setNavLinks2] = useState([]);

  useEffect(() => {
    FetchCategory();
    FetchCategory2();
  }, [user]);

  const FetchCategory = async () => {
    GenToken(
      "eyJhbGciOiJSUzI1NiIsImtpZCI6IjE1MjU1NWEyMjM3MWYxMGY0ZTIyZjFhY2U3NjJmYzUwZmYzYmVlMGMiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiTmFzZWVtIGFoYW1lZCIsImlzcyI6Imh0dHBzOi8vc2VjdXJldG9rZW4uZ29vZ2xlLmNvbS9tYWdhemluZS1hZG1pbiIsImF1ZCI6Im1hZ2F6aW5lLWFkbWluIiwiYXV0aF90aW1lIjoxNjM1MzM4MTcyLCJ1c2VyX2lkIjoiZ09NeGtUQ2p1YmZPNURtbUxJd2htSDZrdjJZMiIsInN1YiI6ImdPTXhrVENqdWJmTzVEbW1MSXdobUg2a3YyWTIiLCJpYXQiOjE2MzUzMzgxNzMsImV4cCI6MTYzNTM0MTc3MywiZW1haWwiOiJuYXNlZW1haGFtZWRpbmZvQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwaG9uZV9udW1iZXIiOiIrOTQ3NzE5MDAxMzYiLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7InBob25lIjpbIis5NDc3MTkwMDEzNiJdLCJlbWFpbCI6WyJuYXNlZW1haGFtZWRpbmZvQGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.iTb7CtFUZw07DiU6V7sNQ6aeCCvQyKEdQ2c9y7JiHfCyR6cplk_HeQVz1SRVPA_0JVbcTkeb0w8jWozNY-54dkrYr09naa1lLQMzLMhy1Oa9bjqIPu_o7R3FXO9tI8yoXhrFRb32jBH7D9__5pTVvrMJt9aRCFwy_uU87VHLmkj3d6MHwS1bagig82eapI56U9ORjqzP3f86hjbM2oa3p2jU_ew9gqB_QhujUdVWoBxoWcblTNUnnxYm8MaWe_nlD-AzquY2C8GwuF5Eqzlel5mUhqLcRRJvj7DGKDlwfPKaR1NTJHwUwJf151azyjnGIqWDF3R2OGNAaOjxt419Cg"
    );

    try {
      await axios({
        method: "POST",

        data: {
          query: `query {
            categories {
              id
              category
            }
          }
          `,
        },
        headers: {
          Accept: `application/json`,
          Authorization: user.token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          const data = res.data;
          if (data != null) {
            const updateCategory = data.data.categories;
            const updateHello = updateCategory?.map((item) => {
              return {
                id: item.id,
                link: `/articals/${`${item.category}`.toLowerCase()}`,
                title: `${item.category}`,
              };
            });
            console.log("is there any aritcle", updateHello);
            setNavLinks(updateHello);
            // setCategory(updateCategory);
          } else {
            alert(res.data.errors[0].message);
          }
        })
        .catch((error) => {
          console.error("Category FetcingError2 ", error);
        });
    } catch (err) {
      console.log("CategoryError2", err);
    }
  };

  const FetchCategory2 = async () => {
    try {
      await axios({
        method: "POST",

        data: {
          query: `query {
            categories {
              id
              category
            }
          }
          `,
        },
        headers: {
          Accept: `application/json`,
          Authorization: user.token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          const data = res.data;
          if (data != null) {
            const updateCategory2 = data.data.categories;
            const updateHello2 = updateCategory2?.map((item) => {
              return {
                id: item.id,
                link: `/newarticals/${`${item.category}`.toLowerCase()}`,
                title: `${item.category}`,
              };
            });
            setNavLinks2(updateHello2);
            // setCategory2(updateCategory2);
          } else {
            alert(res.data.errors[0].message);
          }
        })
        .catch((error) => {
          console.error("Category FetcingError ", error);
        });
    } catch (err) {
      console.log("CategoryError", err);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      <ClearContext.Provider value={{ clear: clear, setClear: setClear }}>
        <div className="App">
          <Router>
            {!user ? (
              <Login />
            ) : (
              <Switch>
                <Route path="/articals">
                  <Header />
                  <PageButtons />
                  <Article />
                </Route>

                {navLinks?.map((item) => {
                  return (
                    <Route path={item.link}>
                      <Header />
                      <PageButtons />
                      <Article />
                    </Route>
                  );
                })}

                <Route path="/newarticals">
                  <Header />
                  <PageButtons />
                  <NewArticles />
                </Route>
                {navLinks2?.map((item) => {
                  return (
                    <Route path={item.link}>
                      <Header />
                      <PageButtons />
                      <NewArticles />
                    </Route>
                  );
                })}
                <Route path="/users">
                  <Header />
                  <PageButtons />
                  <Users />
                </Route>
                <Route path="/home">
                  <Header />
                  <PageButtons />
                  <Home />
                </Route>
              </Switch>
            )}
          </Router>
        </div>
      </ClearContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
