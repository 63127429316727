import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import reducer, { initialState } from "./libraries/reducer";
import { StateProvider } from "./libraries/StateProvider";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { SERVER_URL } from "./libraries/URLs";
const httpLink = createHttpLink({
  uri: SERVER_URL,
});
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? token : "",
    },
  };
});
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </StateProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
