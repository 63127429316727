import jwtDecode from "jwt-decode";
import QueryString from "qs";
import { REFRESH_TOKEN_URL } from "./URLs";


export const GenToken = async (token) => {
  if (!token === undefined) {
    console.error("generator token error");
    return true;
  }
  const { exp } = jwtDecode(token);
  const res = await RefreshTokenRequst(
    "eyJhbGciOiJSUzI1NiIsImtpZCI6IjE1MjU1NWEyMjM3MWYxMGY0ZTIyZjFhY2U3NjJmYzUwZmYzYmVlMGMiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiTmFzZWVtIGFoYW1lZCIsImlzcyI6Imh0dHBzOi8vc2VjdXJldG9rZW4uZ29vZ2xlLmNvbS9tYWdhemluZS1hZG1pbiIsImF1ZCI6Im1hZ2F6aW5lLWFkbWluIiwiYXV0aF90aW1lIjoxNjM1MzM4MTcyLCJ1c2VyX2lkIjoiZ09NeGtUQ2p1YmZPNURtbUxJd2htSDZrdjJZMiIsInN1YiI6ImdPTXhrVENqdWJmTzVEbW1MSXdobUg2a3YyWTIiLCJpYXQiOjE2MzUzMzgxNzMsImV4cCI6MTYzNTM0MTc3MywiZW1haWwiOiJuYXNlZW1haGFtZWRpbmZvQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwaG9uZV9udW1iZXIiOiIrOTQ3NzE5MDAxMzYiLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7InBob25lIjpbIis5NDc3MTkwMDEzNiJdLCJlbWFpbCI6WyJuYXNlZW1haGFtZWRpbmZvQGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.iTb7CtFUZw07DiU6V7sNQ6aeCCvQyKEdQ2c9y7JiHfCyR6cplk_HeQVz1SRVPA_0JVbcTkeb0w8jWozNY-54dkrYr09naa1lLQMzLMhy1Oa9bjqIPu_o7R3FXO9tI8yoXhrFRb32jBH7D9__5pTVvrMJt9aRCFwy_uU87VHLmkj3d6MHwS1bagig82eapI56U9ORjqzP3f86hjbM2oa3p2jU_ew9gqB_QhujUdVWoBxoWcblTNUnnxYm8MaWe_nlD-AzquY2C8GwuF5Eqzlel5mUhqLcRRJvj7DGKDlwfPKaR1NTJHwUwJf151azyjnGIqWDF3R2OGNAaOjxt419Cg"
  );
  console.log("new token response", res);
  return res;
  //   if (Date.now() >= exp * 1000) {
  //   }
  //   return true;
};

const RefreshTokenRequst = async (token) => {
  // REFRESH_TOKEN_URL
  const response = await fetch(REFRESH_TOKEN_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: QueryString.stringify({
      grant_type: "refresh_token",
      refresh_token: token,
    }),
  });
  return response;
};
