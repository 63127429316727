import React, { useState } from "react";
import "../css/SlotCard4.css";
import { Card, Button } from "react-bootstrap";

function SlotCard4() {
  const [bottomSlotSide, setBottomSlotSide] = useState([
    {
      slot: "Slot 01",
      title: "Sed ut perspiciatis unde omnis",
      para: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi ciunt. Neque porro quisquam est.",
    },
  ]);
  return (
    <div className="slotcard4">
      {bottomSlotSide?.map((item) => {
        return (
          <Card className="card4">
            <Button className="card4_btn_slot">{item.slot}</Button>

            <Card.Body className="card4_body">
              <Card.Title className="card4_title">{item.title}</Card.Title>
              <Card.Text className="card4_text">{item.para}</Card.Text>
              <Button className="learn_more4">Learn more</Button>
            </Card.Body>
          </Card>
        );
      })}
    </div>
  );
}

export default SlotCard4;
