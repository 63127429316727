import React from "react";
import "../css/RightSlots.css";
import SlotCard1 from "./SlotCard1";
import SlotCard2 from "./SlotCard2";
import SlotCard3 from "./SlotCard3";
import SlotCard4 from "./SlotCard4";

function RightSlots() {
  return (
    <div className="right_slots">
      <div className="colSide">
        <div className="slot_left">
          <div className="topics">
            <span className="span1">Artical Slot 01</span>
            <span className="span2">Category</span>
          </div>
          <div className="slots_col1_p">
            <SlotCard1 />
          </div>
          <div className="page_text">
            <span>Snap photos and share like never before</span>
          </div>
        </div>

        <div className="slot_right">
          <div className="topics_r">
            <span className="span1">Artical Slot 01</span>
            <span className="span2">Category</span>
          </div>
          <div className="slots_col3_p">
            <SlotCard3 />
          </div>
          <div className="slots_col4_wp">
            <SlotCard4 />
          </div>
        </div>
      </div>
      <div className="slot_bottom">
        <SlotCard2 />
      </div>
    </div>
  );
}

export default RightSlots;
