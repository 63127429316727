import React from "react";
import { Link } from "react-router-dom";
import "../css/PageButtons.css";

function PageButtons() {
  const crlChange1 = () => {
    document.getElementById("btn_ovel1").style.backgroundColor = "black";
    document.getElementById("btn_ovel1").style.color = "white";
    document.getElementById("btn_ovel2").style.backgroundColor = "white";
    document.getElementById("btn_ovel2").style.color = "black";
    document.getElementById("btn_ovel3").style.backgroundColor = "white";
    document.getElementById("btn_ovel3").style.color = "black";
    document.getElementById("btn_ovel4").style.backgroundColor = "white";
    document.getElementById("btn_ovel4").style.color = "black";
  };
  const crlChange2 = () => {
    document.getElementById("btn_ovel2").style.backgroundColor = "black";
    document.getElementById("btn_ovel2").style.color = "white";
    document.getElementById("btn_ovel1").style.backgroundColor = "white";
    document.getElementById("btn_ovel1").style.color = "black";
    document.getElementById("btn_ovel3").style.backgroundColor = "white";
    document.getElementById("btn_ovel3").style.color = "black";
    document.getElementById("btn_ovel4").style.backgroundColor = "white";
    document.getElementById("btn_ovel4").style.color = "black";
  };
  const crlChange3 = () => {
    document.getElementById("btn_ovel3").style.backgroundColor = "black";
    document.getElementById("btn_ovel3").style.color = "white";
    document.getElementById("btn_ovel2").style.backgroundColor = "white";
    document.getElementById("btn_ovel2").style.color = "black";
    document.getElementById("btn_ovel1").style.backgroundColor = "white";
    document.getElementById("btn_ovel1").style.color = "black";
    document.getElementById("btn_ovel4").style.backgroundColor = "white";
    document.getElementById("btn_ovel4").style.color = "black";
  };
  const crlChange4 = () => {
    document.getElementById("btn_ovel4").style.backgroundColor = "black";
    document.getElementById("btn_ovel4").style.color = "white";
    document.getElementById("btn_ovel2").style.backgroundColor = "white";
    document.getElementById("btn_ovel2").style.color = "black";
    document.getElementById("btn_ovel1").style.backgroundColor = "white";
    document.getElementById("btn_ovel1").style.color = "black";
    document.getElementById("btn_ovel3").style.backgroundColor = "white";
    document.getElementById("btn_ovel3").style.color = "black";
  };

  return (
    <div className="pagebutton">
      <div className="butons">
        <Link to="/home">
          <button className="btn_ovel" id="btn_ovel1" onClick={crlChange1}>
            Home Page
          </button>
        </Link>
        <Link to="/articals">
          <button className="btn_ovel" id="btn_ovel2" onClick={crlChange2}>
            Update Article
          </button>
        </Link>
        <Link to="/newarticals">
          <button className="btn_ovel" id="btn_ovel3" onClick={crlChange3}>
            New Article
          </button>
        </Link>
        <Link to="/users">
          <button className="btn_ovel" id="btn_ovel4" onClick={crlChange4}>
            Users
          </button>
        </Link>
      </div>
    </div>
  );
}

export default PageButtons;
