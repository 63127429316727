import { Button } from '@material-ui/core'

import React, { useContext, useEffect, useState } from 'react'

import img from '../images/Respect logo 2.png'

import axios from '../libraries/axios'
import AuthContext from '../libraries/context'

import '../css/Header.css'

const GET_SUBSCRIBED_USERS = /* GraphQL */ `
  query {
    users(where: { isSubscribed: true }) {
      id
    }
  }
`

const SEND_SUMMARY = /* GraphQL */ `
  mutation {
    sendSummary
  }
`

function Header() {
  const { token } = useContext(AuthContext).user
  const [subscriptionCount, setSubscriptionCount] = useState(0)

  useEffect(() => {
    async function fetchSubscriptionCount() {
      const response = await axios({
        method: 'POST',
        data: { query: GET_SUBSCRIBED_USERS },
        headers: {
          Accept: `application/json`,
          Authorization: token,
          'Content-Type': 'application/json',
        },
      })

      if (response.data.errors) {
        alert('Failed to fetch user subscribed count')
        return undefined
      }

      setSubscriptionCount(response.data.data.users.length)
    }

    fetchSubscriptionCount()
  }, [token])

  async function handClickSendSummary() {
    const response = await axios({
      method: 'POST',
      data: { query: SEND_SUMMARY },
      headers: {
        Accept: `application/json`,
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })

    if (response.data.errors) {
      alert('Failed to send the summary')
      return undefined
    }

    alert('Successfully sent summary.')
  }

  return (
    <div className='header'>
      <img className='header_image' src={img} alt='logo' />
      <h1 className='header_text'>Dashboard</h1>
      <aside className='header-right'>
        <p className='header-right__subscription-count'>
          Total subscribed users : {subscriptionCount}
        </p>
        <Button
          variant='contained'
          color='primary'
          disableElevation
          onClick={handClickSendSummary}
          className='header-right__send-summary'>
          Send Summary
        </Button>
      </aside>
    </div>
  )
}

export default Header
