import React, { useContext, useEffect, useState } from "react";
import "../css/AddCategory.css";
import AuthContext from "../libraries/context.js";
import axios from "../libraries/axios";

function AddCategory({ show, setShow, FetchCategory }) {
  const [title, setTitle] = useState("");
  const [createCategory, setCreateCategory] = useState([]);
  const context = useContext(AuthContext);
  const token = context.user.token;

  // useEffect(() => {
  //   HandleAdd();
  // }, []);

  const Hide = () => {
    setShow(false);
    setTitle("")
  };

  const HandleAdd = async (e) => {
    e.preventDefault();
    setShow(false);
    if (title.toString().trim() == "") {
      alert("Please Enter a Category");
    } else {
      try {
        axios({
          method: "POST",

          data: {
            query: `mutation{
            createCategory(data:{            
                  category:"${title}"              
            }){
              id
            }
          }`,
          },
          headers: {
            Accept: `application/json`,
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            console.info("added data ", res.data);
            const data = res.data.data.createCategory;
            if (data != null) {
              FetchCategory();
              alert("Added Successfully");
            } else {
              alert(res.data.errors[0].message);
              // alert(res.data);
            }
          })
          .catch((error) => {
            console.error("FetcingError while adding category ", error);
          });
          setTitle("")
      } catch (err) {
        console.log("Error", err);
      }
    }
  };

  return show === true ? (
    <div className="add__category">
      <div className="modal__close" id="modal__close" onClick={Hide}>
        &times;
      </div>
      <div className="modalCategory" id="modalCategory">
        <span className="confirm__text">Add new category here</span>
        <input
          type="text"
          className="categoryInput"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <div className="confirm__btns">
          <button className="yes" onClick={(e) => HandleAdd(e)}>
            Add
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
}

export default AddCategory;
