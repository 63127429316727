import React, { useState } from "react";
import "../css/SlotCard3.css";
import { Card, Button } from "react-bootstrap";
import { uuid } from "uuidv4";
import img from "../images/image_410b15752c 5.png";

function SlotCard3() {
  const [sideSlot, setSideSlot] = useState([
    {
      sId: uuid(),
      image: img,
      slot: "Slot 01",
      tittle: "Sed ut perspiciatis unde omnis",
      TitlePara:
        "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit autfugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem.",
    },
    {
      sId: uuid(),
      image: img,
      slot: "Slot 02",
      tittle: "Sed ut perspiciatis unde omnis",
      TitlePara:
        "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit autfugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem.",
    },
    {
      sId: uuid(),
      image: img,
      slot: "Slot 03",
      tittle: "Sed ut perspiciatis unde omnis",
      TitlePara:
        "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit autfugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem.",
    },
    {
      sId: uuid(),
      image: img,
      slot: "Slot 04",
      tittle: "Sed ut perspiciatis unde omnis",
      TitlePara:
        "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit autfugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem.",
    },
    {
      sId: uuid(),
      image: img,
      slot: "Slot 05",
      tittle: "Sed ut perspiciatis unde omnis",
      TitlePara:
        "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit autfugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem.",
    },
  ]);

  return (
    <div className="slotcard3">
      {sideSlot?.map((item) => {
        return (
          <Card className="card3">
            <Button className="card3_btn_slot">{item.slot}</Button>
            <div className="card3_box">
              <Card.Img
                className="card3_img"
                alt="Article Photo"
                src={item.image}
              />
              <div className="innerBox2"></div>
            </div>

            <Card.Body className="card3_body">
              <Card.Title className="card3_title">{item.tittle}</Card.Title>
              <Card.Text className="card3_text">{item.TitlePara}</Card.Text>
            </Card.Body>
          </Card>
        );
      })}
    </div>
  );
}

export default SlotCard3;
