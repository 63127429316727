import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../css/NavbarE.css";
import AddCategory from "./AddCategory";
import AuthContext from "../libraries/context.js";
import axios from "../libraries/axios";
import Loader from "../pages/Loader.js";
import ClearContext from "../libraries/ClearContext";

function NavbarE({
  setAddition,
  setEditor,
  setDate,
  setTitle,
  setSubTitle,
  setPara1,
  setPara2,
  setPara3,
  setPara4,
  setPara5,
  setPara6,
  setImage1,
  setImage2,
  setImage3,
  setImage4,
  setImage5,
  setImage6,
  setEditUploadImg1,
  setEditUploadImg2,
  category,
  setCategory,
  navLinks,
  setNavLinks,
  visibility,
  setVisibility,
  FetchCategory,
}) {
  const history = useHistory();
  const [show, setShow] = useState(false);

  const context = useContext(AuthContext);
  const token = context.user.token;

  // const [category, setCategory] = useState([]);
  // const [navLinks, setNavLinks] = useState([]);
  // const [visibility, setVisibility] = useState(false);
  const clearContext = useContext(ClearContext);

  // useEffect(() => {
  //   FetchCategory();
  // }, []);

  // const FetchCategory = async () => {
  //   try {
  //     setVisibility(true);
  //     await axios({
  //       method: "POST",

  //       data: {
  //         query: `query {
  //           categories {
  //             id
  //             category
  //             isActive
  //           }
  //         }
  //         `,
  //       },
  //       headers: {
  //         Accept: `application/json`,
  //         Authorization: token,
  //         "Content-Type": "application/json",
  //       },
  //     })
  //       .then((res) => {
  //         const data = res.data;
  //         if (data != null) {
  //           const updateCategory = data.data.categories;
  //           const isActiveTrueFiles = updateCategory?.filter(
  //             (item) => item.isActive === "true"
  //           );
  //           const updateHello = isActiveTrueFiles?.map((item) => {
  //             return {
  //               id: item.id,
  //               link: `/articals/${`${item.category}`.toLowerCase()}`,
  //               title: `${item.category}`,
  //             };
  //           });
  //           setNavLinks(updateHello);
  //           setCategory(isActiveTrueFiles);
  //         } else {
  //           alert(res.data.errors[0].message);
  //         }
  //         setVisibility(false);
  //       })
  //       .catch((error) => {
  //         console.error("Category FetcingError2 ", error);
  //         setVisibility(false);
  //       });
  //   } catch (err) {
  //     console.log("CategoryError2", err);
  //     setVisibility(false);
  //   }
  // };

  const ShowMdl = () => {
    setShow(true);
  };

  const Goto = (id, title, link) => {
    clearContext?.setClear(title);
    history.push({
      pathname: link,
      state: {
        id: id,
        title: title,
      },
    });

    setSubTitle("");
    setEditor("");
    setTitle("");
    setEditUploadImg1("");
    setEditUploadImg2("");
    setAddition("");
    setDate("");
    setPara1("");
    setPara2("");
    setPara3("");
    setPara4("");
    setPara5("");
    setPara6("");
    setImage1("");
    setImage2("");
    setImage3("");
    setImage4("");
    setImage5("");
    setImage6("");
  };
  return (
    <div className="navbarE">
      <div className="navE">
        <Loader visibility={visibility} />
        <div className="navE_items">
          {navLinks?.map((item) => {
            return (
              <span
                className="span"
                onClick={() => Goto(item.id, item.title, item.link)}
              >
                {item.title}
              </span>
            );
          })}
          <span className="span">
            <button className="btn_add" onClick={() => ShowMdl()}>
              +
            </button>
          </span>
        </div>
        <div className="add__modal">
          <AddCategory
            show={show}
            setShow={setShow}
            FetchCategory={FetchCategory}
          />
        </div>
      </div>
    </div>
  );
}

export default NavbarE;
